@use "mixins" as *
.bannerModal
  :global(.modal)
    max-width: 90%
    padding: 10px 0
    overflow: auto
  ::-webkit-scrollbar
    width: 8px
    height: 4px

  ::-webkit-scrollbar-thumb
    background: rgba(#3e4550, 0.8)
    border: 2px solid #2c3138
    border-radius: 10px

.banners
  display: inline-flex
  align-items: center
  justify-content: center

.close
  width: 30px
  height: 30px
  border-radius: 20px
  background: #000
  border: 1px solid #1d1d1d
  @include flex
  justify-content: center
  position: absolute
  top: 0
  right: 0
  z-index: 2
  > span
    @include flex
  svg
    opacity: 0.5
    width: 12px
    height: 12px
    path
      fill: #fff
  &:hover svg
    opacity: 0.8

.bannerItem
  @include flex
  overflow: hidden
  border-radius: 15px
  position: relative
  box-shadow: 0 0 10px rgba(#000, 0.5)
  &:not(:first-of-type)
    margin-left: 100px
  > a, > button
    @include flex
    height: auto
    padding: 0
    border-radius: unset
    background: var(--preload_bg)
