@use "mixins" as *
.notification
  min-width: 200px
  min-height: 60px
  padding: 10px 12px
  display: flex
  justify-content: space-between
  flex-direction: column
  background: rgba(#333840, 0.9)
  color: rgb(var(--secondaryColor))
  border-radius: 3px

  > span:first-of-type
    @include bold
    @include flex

    :global(.imageNext)
      margin-right: 10px

  &.error
    background: rgba(var(--particularColor2), 0.7)

  &.success
    background: rgba(var(--particularColor), 0.6)

  &.warning
    background: rgba(var(--particularColor), 0.6)
