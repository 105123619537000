@use "mixins" as *
.scrollbar
  height: 100%

.scrollableContent.showVerticalScrollBar
  transition: top 0.7s ease-out, transform 0.7s ease-out
  padding-right: 10px

.verticalScrollbar
  position: absolute
  border-right: 1px solid var(--scroll_border)
  border-left: 1px solid var(--scroll_border)
  background: var(--scroll_bg)
  width: 10px
  padding: 0 2px
  z-index: 10
  top: 0
  right: 0
  height: 100%

.scrollButton
  background: var(--scroll_buttonBg)
  width: 4px
  border-radius: 10px
  transition: background 0.4s, top 0.7s ease-out
  position: relative
.scrollbar
  height: 100%

.scrollableContent.showVerticalScrollBar
  transition: top 0.7s ease-out, transform 0.7s ease-out
  padding-right: 10px

.verticalScrollbar
  position: absolute
  border-right: 1px solid var(--scroll_border)
  border-left: 1px solid var(--scroll_border)
  background: var(--scroll_bg)
  width: 10px
  padding: 0 2px
  z-index: 10
  top: 0
  right: 0
  height: 100%

.scrollButton
  background: var(--scroll_buttonBg)
  width: 4px
  border-radius: 10px
  transition: background 0.4s, top 0.7s ease-out
  position: relative