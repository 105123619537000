@use "mixins" as *
.button
  padding: 0 8px
  @include flex
  justify-content: center
  line-height: var(--button_fontSize)
  font-size: var(--button_fontSize)
  border: 0
  height: var(--button_height)
  border-radius: var(--base_borderRadius)
  color: var(--button_color)
  @include bold
  position: relative

  &:hover:not(:active)
    @include pointer()

  :global(.loader)
    > span
      font-size: var(--button_fontSize)!important
      width: var(--button_fontSize)!important
      height: var(--button_fontSize)!important
      color: rgba(var(--secondaryColor, 0.8))

.primary
  background: var(--button_primaryBg)
  height: 28px
  min-width: 100px
  max-width: 200px
  border-radius: 0

  &:hover
    background: var(--button_primaryHoverBg)

  &:active
    background: var(--button_primaryActiveBg)

.secondary
  background: rgb(var(--particularColor))
  min-width: 86px

  &:hover:not(:active)
    background: var(--button_secondaryHoverBg)

  &:active
    background: var(--button_secondaryActiveBg)

.darkGreyBtn
  background: #494f57
  min-width: 50px
  color: rgb(var(--secondaryColor))

  &:hover:not(:active)
    background: #3f454f

  &:active
    background: #353c46

.betSlipButton
  border: 1px solid transparent
  border-radius: var(--base_borderRadius)
  background: rgb(var(--particularColor))
  box-shadow: inset 0 1px rgb(var(--particularColor))
  @include bold
  font-size: var(--button_fontSize)
  color: var(--button_color)
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3)
  height: var(--button_height)
  display: inline-flex
  align-items: center
  justify-content: center
  min-width: 70px
  max-width: 200px
  margin-right: 4px

  &:hover
    background: var(--button_secondaryHoverBg)
    box-shadow: inset 0 1px var(--button_secondaryHoverBg)
    @include pointer

  &:active
    box-shadow: none
    background: var(--button_secondaryActiveBg)

.thirdary
  background: var(--button_thirdaryBg)
  min-width: 70px
  height: 26px
  padding: 0 6px
  color: #21262c

  &:hover
    background: var(--button_thirdaryHoverBg)

  &:active
    background: var(--button_thirdaryActiveBg)

.greenBtn
  background: #5cba60
  min-width: 70px
  padding: 0 6px

  &:hover
    background: #4bae4f

  &:active
    background: #3fa143

.cancelBtn,
.saveBtn
  background: none
  padding: 6px 8px
  margin: 0 4px
  border-radius: 0
  font-weight: normal
  height: 100%

  &:hover:not(:active)
    box-shadow: inset 0 -1px

  &:active
    opacity: 0.7

.saveBtn
  color: var(--account_agentCashbackSaveFill)

.cancelBtn
  position: relative
  color: rgb(var(--secondaryColor))
  &.iconReset:before
    margin-right: 8px

  &:after
    content: ""
    width: 1px
    height: 12px
    background-image: linear-gradient(var(--account_personalDetailsSeparator) 33%,rgba(255, 255, 255, 0) 0%)
    background-position: right
    background-size: 1px 2px
    background-repeat: repeat-y
    position: absolute
    right: -4px

.disabled
  opacity: 0.5
  pointer-events: none
  cursor: default !important

//icons
.iconCancel:before
  content: ""
  margin-right: 8px
  width: 8px
  height: 8px
  background-color: var(--icon_base_fill)
  mask-image: var(--icon-button-cancel)

.iconSave:before
  content: ""
  margin-right: 8px
  width: 12px
  height: 8px
  background-color: var(--account_agentCashbackSaveFill)
  mask-image: var(--icon-button-save)

.iconReset:before
  content: ""
  width: 10px
  height: 10px
  background-color: rgb(var(--secondaryColor))
  mask-image: var(--icon-button-reset)
