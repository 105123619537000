@use "mixins" as *
body
  --icon-casino-play: url("https://bra.inplay.stream/assets/common/desktop/base-icons/casino-play.webp")
  --icon-favorite: url("https://bra.inplay.stream/assets/common/desktop/base-icons/favorite.svg")
  --icon-multiview-selected: url("https://bra.inplay.stream/assets/common/desktop/base-icons/multiview-selected.svg")
  --icon-esport-default: url("https://bra.inplay.stream/assets/common/desktop/live-scoreboards/esport-default.webp")
  --icon-esport-default-flat: url("https://bra.inplay.stream/assets/common/desktop/live-scoreboards-flat/esport-default.webp")
  --icon-not-found-red-bubble: url("https://bra.inplay.stream/assets/common/desktop/not-found/red-bubble.webp")
  --icon-search: url("https://bra.inplay.stream/assets/common/desktop/base-icons/account-search.svg")
  --icon-close: url("https://bra.inplay.stream/assets/common/desktop/base-icons/close-white.svg")
  --icon-button-save: url("https://bra.inplay.stream/assets/common/desktop/base-icons/account-save.svg")
  --icon-button-cancel: url("https://bra.inplay.stream/assets/common/desktop/base-icons/account-cancel.svg")
  --icon-button-reset: url("https://bra.inplay.stream/assets/common/desktop/base-icons/account-reset.svg")
  --icon-button-edit: url("https://bra.inplay.stream/assets/common/desktop/base-icons/account-edit.svg")
  --icon-dropdown-arrow: url("https://bra.inplay.stream/assets/common/desktop/base-icons/dropdown-arrow.svg")
  --icon-password-show: url("https://bra.inplay.stream/assets/common/desktop/base-icons/password-show.svg")
  --icon-password-hide: url("https://bra.inplay.stream/assets/common/desktop/base-icons/password-notshow.svg")
  --icon-checkbox-check: url("https://bra.inplay.stream/assets/common/desktop/base-icons/checkbox-checked-icon.svg")
  --icon-casino-compact-search: url("https://bra.inplay.stream/assets/common/casino/compact/search.svg")
  --icon-increased: url("https://bra.inplay.stream/assets/common/desktop/base-icons/increased.png")
  --icon-increased-active: url("https://bra.inplay.stream/assets/common/desktop/base-icons/increased-active.png")
  --icon-decreased: url("https://bra.inplay.stream/assets/common/desktop/base-icons/decreased.png")
  --icon-decreased-active: url("https://bra.inplay.stream/assets/common/desktop/base-icons/decreased-active.png")
  --icon-notification-bg: url("https://bra.inplay.stream/assets/common/notification-image.webp")
  --icon-wallet-tab-corner: url("https://bra.inplay.stream/assets/common/wallet/tab-corner.svg")

  --specialFont: "helveticaNeue"
  --specialFontBoldCondensed: "helveticaNeueBoldCondensed"
  --specialFontCurrency: "currency"
  --icon_base_fill: rgb(var(--primaryColor))
  --common_field_labelColor: rgb(var(--primaryColor))
  --checkbox_bg: rgb(var(--secondaryColor))
  --checkbox_checked_fill: #21262c
  --common_dropdown_placeholderColor: rgba(var(--primaryColor), .5)
  --common_verification-inputBg: var(--field_bg)
  --common_verification-inputBorder: var(--field_bg)
  --common_verification-inputFocusBg: transparent
