@use "mixins" as *
.opacity
  &.not-visible
    opacity: 0
    transition: opacity 0.7s ease-in-out

  &.visible
    opacity: 1

.modalBaseAnimate
  &.not-visible
    > div:first-of-type
      opacity: 0
      transition: opacity 0.5s ease-in-out

    :global(.modal)
      opacity: 0
      transition: all 0.3s ease-in-out
      transform: translateY(-80px)

  &.visible
    > div:first-of-type
      opacity: 1

    :global(.modal)
      opacity: 1
      transform: translateY(0)